import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Checkbox,
  Pagination,
  MenuItem,
  TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  PaginationState,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';

type FileData = {
  id: number;
  fileName: string;
  filePath: string;
  download?: string;
  lastModified?: Date;
};

const apiUrl = process.env.REACT_APP_API_URL;

const ViewFiles = () => {
  const [total, setTotal] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>({});

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const batchNumber = queryParams.get('batchNumber');
  const type = queryParams.get('type');
  const subType = queryParams.get('sub_type');

  const fetchRegularData = async (pageIndex: number, pageSize: number) => {
    setIsLoading(true);
    try {
      const currUrl = `${apiUrl}/dashboard/view_files/${batchNumber}`;

      const params: {
        page: number;
        limit: number;
        type: string | null;
        sub_type?: string | null;
      } = {
        page: pageIndex + 1,
        limit: pageSize,
        type: type,
      };

      if (subType !== '' && subType !== null) {
        params.sub_type = subType;
      }

      const response = await axios.get(currUrl, { params });
      const { data, count } = response.data;
      setTotal(count);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const searchFiles = async (pageIndex: number, pageSize: number) => {
    setIsLoading(true);
    try {
      const searchUrl = `${apiUrl}/dashboard/search-files/${batchNumber}`;
      const params = {
        searchTerm,
        page: pageIndex + 1,
        limit: pageSize,
      };

      const response = await axios.get(searchUrl, { params });
      const { data, count } = response.data;
      setTotal(count);
      return data;
    } catch (error) {
      console.error('Error searching files:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      clearSearch();
      return;
    }
    
    setIsSearchMode(true);
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    searchFilesQuery.refetch();
  };

  const clearSearch = () => {
    setSearchTerm('');
    setIsSearchMode(false);
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    regularDataQuery.refetch();
  };

  useEffect(() => {
    setRowSelection({});
  }, [pagination.pageIndex, pagination.pageSize, isSearchMode]);

  const regularDataQuery = useQuery({
    queryKey: ['regularData', pagination],
    queryFn: () => fetchRegularData(pagination.pageIndex, pagination.pageSize),
    placeholderData: keepPreviousData,
    enabled: !isSearchMode,
  });

  const searchFilesQuery = useQuery({
    queryKey: ['searchFiles', searchTerm, pagination],
    queryFn: () => searchFiles(pagination.pageIndex, pagination.pageSize),
    placeholderData: keepPreviousData,
    enabled: isSearchMode && searchTerm.trim() !== '',
  });

  const displayData = isSearchMode ? searchFilesQuery.data || [] : regularDataQuery.data || [];

  const columnHelper = createColumnHelper<FileData>();

  const handleCellClick = (
    fileName: string,
    columnType: string,
  ) => {
    console.log('fileName clicked:', fileName);
  };

  const backToDashboard = () => {
    navigate('/');
  };

  const downloadSelectedFiles = async () => {
    const selectedRows = table.getState().rowSelection;
    const currentPageData = displayData.filter(
      (row: any, index: number) => selectedRows[index]
    );
    const fileKeys = currentPageData.map((row: any) => row.filePath);
    await handleDownload(fileKeys);
  };

  const handleDownload = async (fileKeys: string[]) => {
    try {
      if (fileKeys.length > 0) {
        const response = await axios.post(
          `${apiUrl}/dashboard/download-files`,
          {
            fileKeys: fileKeys,
          },
          { responseType: 'blob' }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'files.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('File path is undefined');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const columns = [
    {
      id: 'select-col',
      header: ({ table }: { table: any }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }: { row: Row<FileData> }) => (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
    },
    columnHelper.accessor('fileName', {
      cell: (info) => (
        <Button
          variant="text"
          sx={{
            color: '#3f51b5',
            textDecoration: 'underline',
            fontWeight: 600,
          }}
          onClick={() => handleCellClick(info.getValue(), 'fileName')}
        >
          {info.getValue()}
        </Button>
      ),
      header: () => <span style={{ fontWeight: 600 }}>Document Name</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('download', {
      cell: (info) => (
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none' }}
          onClick={() => handleDownload([info.row.original.filePath])}
        >
          Download File <DownloadIcon sx={{ marginLeft: '4px' }} />
        </Button>
      ),
      header: () => <span style={{ fontWeight: 600 }}>Download File</span>,
      footer: (info) => info.column.id,
    }),
  ];

  const startIndex = pagination.pageIndex * pagination.pageSize + 1;
  const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

  const table = useReactTable({
    data: displayData,
    columns,
    pageCount: Math.ceil(total / pagination.pageSize),
    state: {
      pagination,
      rowSelection,
    },
    onPaginationChange: (updater) => {
      setPagination((prev) => {
        const newPagination =
          typeof updater === 'function' ? updater(prev) : updater;
        return {
          ...newPagination,
        };
      });
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
  });

  function toPascalCase(input: string): string {
    return input
      .replace(/([-_ ]\w)/g, (match: string) => match.charAt(1).toUpperCase())
      .replace(/^[a-z]/, (match: string) => match.toUpperCase())
      .replace(/[-_ ]/g, '');
  }

  const pageTitle = () => {
    const formattedType = toPascalCase(type || '');
    const formattedSubType = subType ? toPascalCase(subType) : '';
    return `${formattedType} ${formattedSubType} Files`.trim();
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={600}>
            {pageTitle()}
          </Typography>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Total Files: {total}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={backToDashboard}
          >
            Back to Dashboard
          </Button>
          <TextField
            placeholder="Search files..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            sx={{ width: '250px' }}
          />
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleSearch}
          >
            Search
          </Button>
          {isSearchMode && (
            <Button 
              variant="outlined" 
              color="secondary"
              onClick={clearSearch}
            >
              Clear Search
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={downloadSelectedFiles}
            disabled={Object.keys(rowSelection).length === 0}
          >
            Download Selected Files
          </Button>
        </Box>
      </Box>

      {isLoading ? (
        <Typography variant="h6">Loading data...</Typography>
      ) : (
        <div
          style={{
            maxHeight: '600px',
            overflowY: 'auto',
            border: '1px solid #ddd',
          }}
        >
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
            }}
          >
            <thead
              style={{
                position: 'sticky',
                top: 0,
                background: '#f5f5f5',
                zIndex: 3,
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        padding: '8px',
                        textAlign: 'center',
                        background: '#f5f5f5',
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}>
                    {isSearchMode ? "No matching files found. Try a different search term." : "No files available."}
                  </td>
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          border: '1px solid #ddd',
                          padding: '8px',
                          textAlign: 'center',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          marginTop: '16px',
        }}
      >
        {total > 0 && (
          <>
            <span style={{ marginRight: '16px' }}>
              Showing {startIndex} to {endIndex} of {total?.toLocaleString()} Rows
            </span>

            <TextField
              select
              label="Per Page"
              value={pagination.pageSize}
              onChange={(e) => {
                setPagination((prev) => ({
                  ...prev,
                  pageSize: Number(e.target.value),
                  pageIndex: 0,
                }));
              }}
              sx={{
                width: '100px',
                marginRight: '16px',
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiInputBase-root': {
                  height: '30px',
                },
              }}
              variant="outlined"
            >
              {[100, 200].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </TextField>

            <Pagination
              count={table.getPageCount()}
              page={table.getState().pagination.pageIndex + 1}
              onChange={(event, value) => {
                table.setPageIndex(value - 1);
              }}
              variant="outlined"
              shape="rounded"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ViewFiles;