import { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  PaginationState,
} from '@tanstack/react-table';
import {
  Box,
  Button,
  Typography,
  TextField,
  Pagination,
  MenuItem,
  Popover,
  Checkbox,
  FormControlLabel,
  Paper,
  IconButton,
  Divider,
  Input,
} from '@mui/material';
import { FilterList as FilterIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const apiUrl = process.env.REACT_APP_API_URL;

const App = () => {
  type Dashboard = {
    id: number;
    batchNumber: string;
    standardReview: number;
    standardDelivered: number;
    standardTotal: number;
    deliveryDateStandard: string;
    batchIncomingDate: string;
    deliveryDateNonStandard: string;
    nonStandardReview: number;
    nonStandardDelivered: number;
    nonStandardTotal: number;
    pipelineErrors: number;
    totalUniqueVatNumbers: number;
    notProcessedIndividualNumbers: number;
    totalIndividualNumbers: number;
    batchOutput?: any;
    numberOfBs: number;
    numberOfIs: number;
    numberOfBsAndIs: number;
  };

  const [total, setTotal] = useState<number>(0);
  const [filterValue, setFilterValue] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downloadingBatch, setDownloadingBatch] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeColumn, setActiveColumn] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<Record<string, string[]>>(
    {}
  );
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [filterSearch, setFilterSearch] = useState<string>('');

  const navigate = useNavigate();

  const handleFilterClick = (
    event: React.MouseEvent<HTMLElement>,
    columnId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveColumn(columnId);
    setFilterSearch('');
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setActiveColumn(null);
    setFilterSearch('');
  };

  const applyFilter = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    dataQuery.refetch();
  };

  // Helper function for date formatting
  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const formatExcelDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Helper function for batch number sorting
  const sortBatchNumbers = (a: string, b: string) => {
    const numA = parseInt(a.replace(/\D/g, ''));
    const numB = parseInt(b.replace(/\D/g, ''));
    return numA - numB;
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { pageIndex, pageSize } = pagination;
      const params: any = {
        page: pageIndex + 1,
        limit: pageSize,
        filter: {},
      };

      if (filterValue !== '') {
        params.filter.value = filterValue;
      }
      Object.entries(columnFilters).forEach(([column, values]) => {
        if (values.length > 0) {
          console.log(column);
          if (
            column != 'batchIncomingDate' &&
            column != 'deliveryDateStandard' &&
            column != 'deliveryDateNonStandard'
          ) {
            params.filter[`${column}`] = values;
          } else {
            params.filter[`${column}`] = values.map((date) => {
              const splitDate = date.split('/');
              const joinedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
              return joinedDate;
            });
          }
        }
      });
      if (Object.keys(params.filter).length > 0) {
        params.filter = JSON.stringify(params.filter);
      }
      const response = await axios.get(`${apiUrl}/dashboard`, { params });
      const { data, count } = response.data;
      setTotal(count);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const dataQuery = useQuery({
    queryKey: ['data', pagination, columnFilters, filterValue],
    queryFn: () => fetchData(),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });

  const data = dataQuery.data || [];

  const getUniqueValues = (columnId: string) => {
    const valueMap = new Map();
    data.forEach((row: any) => {
      const value = row[columnId];
      // Format dates before adding to the map
      const displayValue = columnId.includes('Date')
        ? formatDate(value) // This will use the existing formatDate function that returns dd/mm/yyyy
        : value;
      valueMap.set(displayValue, (valueMap.get(displayValue) || 0) + 1);
    });

    let entries = Array.from(valueMap.entries()).map(([value, count]) => ({
      value,
      count,
    }));

    // For dates, we don't need special sorting since they're already formatted
    if (columnId === 'batchNumber') {
      entries.sort((a, b) =>
        sortBatchNumbers(String(a.value), String(b.value))
      );
    } else {
      entries.sort((a, b) => String(a.value).localeCompare(String(b.value)));
    }

    return entries;
  };

  const getColumnSum = (columnId: string) => {
    const sum = data.reduce((acc: number, row: any) => {
      const value = parseFloat(row[columnId]);
      return !isNaN(value) ? acc + value : acc;
    }, 0);
    return sum.toLocaleString();
  };
  const handleDownload = async (batchNumber: string) => {
    if (downloadingBatch) return;

    setDownloadingBatch(batchNumber);
    let url: string | null = null;

    try {
      const response = await axios.get(
        `${apiUrl}/dashboard/batch-download-files/${batchNumber}`,
        {
          responseType: 'blob',
          timeout: 1200000,
        }
      );

      if (!(response.data instanceof Blob)) {
        throw new Error('Invalid response format');
      }

      url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `batch-${batchNumber}.zip`;
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to download the batch files. Please try again.');
    } finally {
      if (url) {
        window.URL.revokeObjectURL(url);
      }
      setDownloadingBatch(null);
    }
  };

  const handleStandardDeliverablesDownload = async (batchNumber: string) => {
    let url: string | null = null;
    if (!isDownloading) {
      try {
        setIsDownloading(true);
        const response = await axios.post(
          `${apiUrl}/dashboard/download-deliverables`,
          {
            batchNumber: batchNumber.replace(/batch/i, '').trim(),
          },
          {
            responseType: 'blob',
            timeout: 1200000,
          }
        );

        if (!(response.data instanceof Blob)) {
          throw new Error('Invalid response format');
        }

        url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = `batch-${batchNumber}.zip`;
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        setIsDownloading(false);
        console.error('Download failed:', error);
        alert('Failed to download the Deliverable files. Please try again.');
      } finally {
        if (url) {
          window.URL.revokeObjectURL(url);
        }
        setIsDownloading(false);

        setDownloadingBatch(null);
      }
    } else {
      alert(
        'A download is already in progress. Please wait for it to complete.'
      );
    }
  };

  const createFilterableHeader = (label: string, columnId: string) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <span style={{ fontSize: '14px', fontWeight: 600 }}>
          {label}
          {columnFilters[columnId]?.length > 0 && (
            <span
              style={{ fontSize: '12px', color: '#666', marginLeft: '4px' }}
            >
              (Filtered)
            </span>
          )}
        </span>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {columnFilters[columnId]?.length > 0 && (
            <IconButton
              size="small"
              onClick={() => clearFilter(columnId)}
              sx={{ ml: 1 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            size="small"
            onClick={(e) => handleFilterClick(e, columnId)}
            sx={{ ml: 1 }}
          >
            <FilterIcon
              fontSize="small"
              color={columnFilters[columnId]?.length ? 'primary' : 'action'}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  const handleCellClick = (
    batchNumber: string,
    type: string,
    sub_type: string = ''
  ) => {
    navigate(
      `/view-files?batchNumber=${batchNumber}&type=${type}${sub_type ? `&sub_type=${sub_type}` : ''}`
    );
  };

  const createClickableCell = (
    value: any,
    batchNumber: string,
    type: string,
    sub_type: string = '',
    isOnclickEnabled: boolean = true
  ) => (
    <Button
      variant="text"
      sx={{
        color: '#3f51b5',
        // textDecoration: 'underline',
        fontWeight: 600,
      }}
      onClick={
        isOnclickEnabled
          ? () => handleCellClick(batchNumber, type, sub_type)
          : () => {}
      }
    >
      {value}
    </Button>
  );

  const createUnClickableCell = (value: any) => (
    <Button
      variant="text"
      sx={{
        color: '#3f51b5',
        // textDecoration: 'underline',
        fontWeight: 600,
      }}
    >
      {value}
    </Button>
  );

  const columnHelper = createColumnHelper<Dashboard>();
  const columns = [
    columnHelper.accessor('batchNumber', {
      cell: (info) =>
        createClickableCell(info.getValue(), info.getValue(), 'batch'),
      header: () => createFilterableHeader('Batch Number', 'batchNumber'),
    }),
    columnHelper.accessor('batchIncomingDate', {
      cell: (info) => createUnClickableCell(formatDate(info.getValue())),
      header: () =>
        createFilterableHeader('Batch Incoming Date', 'batchIncomingDate'),
    }),
    columnHelper.accessor('totalIndividualNumbers', {
      cell: (info) =>
        createClickableCell(
          info.getValue(),
          info.row.original.batchNumber,
          'total_individual',
          '',
          false
        ),
      header: () => (
        <span style={{ fontWeight: 600, fontSize: '14px' }}>
          Total Individual Numbers
        </span>
      ),
    }),
    columnHelper.accessor('totalUniqueVatNumbers', {
      cell: (info) =>
        createClickableCell(
          info.getValue(),
          info.row.original.batchNumber,
          'total_individual'
        ),
      header: () => (
        <span style={{ fontWeight: 600, fontSize: '14px' }}>
          Total Unique VAT Numbers
        </span>
      ),
    }),
    columnHelper.accessor('notProcessedIndividualNumbers', {
      cell: (info) =>
        createClickableCell(
          info.getValue(),
          info.row.original.batchNumber,
          'not_processed'
        ),
      header: () => (
        <span style={{ fontWeight: 600, fontSize: '14px' }}>
          Not Processed Individual Numbers
        </span>
      ),
    }),
    columnHelper.accessor('pipelineErrors', {
      cell: (info) =>
        createClickableCell(
          info.getValue(),
          info.row.original.batchNumber,
          'pipeline_errors'
        ),
      header: () => (
        <span style={{ fontWeight: 600, fontSize: '14px' }}>
          Pipeline Errors
        </span>
      ),
    }),
    columnHelper.group({
      id: 'standard',
      header: () => <span style={{ fontWeight: 600 }}>Standard</span>,
      columns: [
        columnHelper.accessor('standardDelivered', {
          cell: (info) =>
            createClickableCell(
              info.getValue(),
              info.row.original.batchNumber,
              'standard',
              'delivered'
            ),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>Delivered</span>
          ),
        }),
        columnHelper.display({
          id: 'download',
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>
              Deliverables
            </span>
          ),
          cell: (info) => (
            <Button
              variant="text"
              onClick={() =>
                handleStandardDeliverablesDownload(
                  info.row.original.batchNumber
                )
              }
            >
              <DownloadIcon fontSize="small" />
            </Button>
          ),
        }),
        columnHelper.accessor('standardReview', {
          cell: (info) =>
            createClickableCell(
              info.getValue(),
              info.row.original.batchNumber,
              'standard',
              'review'
            ),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>Review</span>
          ),
        }),
        columnHelper.accessor('numberOfBs', {
          cell: (info) => createUnClickableCell(info.getValue()),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>BS Only</span>
          ),
        }),
        columnHelper.accessor('numberOfIs', {
          cell: (info) => createUnClickableCell(info.getValue()),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>PL Only</span>
          ),
        }),
        columnHelper.accessor('numberOfBsAndIs', {
          cell: (info) => createUnClickableCell(info.getValue()),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>BS and PL</span>
          ),
        }),
        columnHelper.accessor('standardTotal', {
          cell: (info) =>
            createClickableCell(
              info.getValue(),
              info.row.original.batchNumber,
              'standard',
              'total'
            ),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>Total</span>
          ),
        }),
        columnHelper.accessor('deliveryDateStandard', {
          cell: (info) => createUnClickableCell(formatDate(info.getValue())),
          header: () =>
            createFilterableHeader('Delivery Date', 'deliveryDateStandard'),
        }),
      ],
    }),
    columnHelper.group({
      id: 'nonStandard',
      header: () => <span style={{ fontWeight: 600 }}>Non Standard</span>,
      columns: [
        columnHelper.accessor('nonStandardDelivered', {
          cell: (info) =>
            createClickableCell(
              info.getValue(),
              info.row.original.batchNumber,
              'non_standard',
              'delivered'
            ),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>Delivered</span>
          ),
        }),
        columnHelper.accessor('nonStandardReview', {
          cell: (info) =>
            createClickableCell(
              info.getValue(),
              info.row.original.batchNumber,
              'non_standard',
              'review'
            ),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>Review</span>
          ),
        }),
        columnHelper.display({
          id: 'nonStandardBs',
          cell: () => createUnClickableCell(0),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>BS Only</span>
          ),
        }),
        columnHelper.display({
          id: 'nonStandardPl',
          cell: () => createUnClickableCell(0),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>PL Only</span>
          ),
        }),
        columnHelper.display({
          id: 'nonStandardnoBsPl',
          cell: () => createUnClickableCell(0),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>
              No BS & PL
            </span>
          ),
        }),
        columnHelper.display({
          id: 'nonStandardBsPl',
          cell: () => createUnClickableCell(0),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>BS and PL</span>
          ),
        }),
        columnHelper.accessor('nonStandardTotal', {
          cell: (info) =>
            createClickableCell(
              info.getValue(),
              info.row.original.batchNumber,
              'non_standard',
              'total'
            ),
          header: () => (
            <span style={{ fontWeight: 600, fontSize: '14px' }}>Total</span>
          ),
        }),
        columnHelper.accessor('deliveryDateNonStandard', {
          cell: (info) => createUnClickableCell(formatDate(info.getValue())),
          header: () =>
            createFilterableHeader('Delivery Date', 'deliveryDateNonStandard'),
        }),
      ],
    }),
  ];
  const FilterPopover = ({ columnId }: { columnId: string }) => {
    const uniqueValues = getUniqueValues(columnId);
    const isNumeric = typeof data[0]?.[columnId] === 'number';
    const filteredValues = uniqueValues.filter(({ value }) =>
      String(value).toLowerCase().includes(filterSearch.toLowerCase())
    );

    const handleFilterChange = (value: any) => {
      const currentFilters = columnFilters[columnId] || [];
      const newFilters = currentFilters.includes(String(value))
        ? currentFilters.filter((v) => v !== String(value))
        : [...currentFilters, String(value)];
      setColumnFilters((prev) => ({
        ...prev,
        [columnId]: newFilters,
      }));
    };

    return (
      <Paper sx={{ p: 2, maxHeight: 400, overflow: 'auto', minWidth: 250 }}>
        {filteredValues.map(({ value, count }) => (
          <FormControlLabel
            key={String(value)}
            control={
              <Checkbox
                checked={
                  columnFilters[columnId]?.includes(String(value)) || false
                }
                onChange={() => handleFilterChange(value)}
              />
            }
            label={`${String(value)} (${count})`}
            sx={{ display: 'block', ml: 0, mb: 1 }}
          />
        ))}
        <Box
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}
        >
          <Button
            size="small"
            onClick={() => {
              setColumnFilters((prev) => {
                const newFilters = { ...prev };
                delete newFilters[columnId];
                return newFilters;
              });
              handleFilterClose();
              applyFilter();
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              handleFilterClose();
              applyFilter();
            }}
          >
            Apply
          </Button>
        </Box>
      </Paper>
    );
  };

  const exportToExcel = () => {
    const getFlattenedData = () => {
      return data.map((row: Dashboard) => {
        const flatRow: Record<string, any> = {
          'Batch Number': row.batchNumber,
          'Batch Incoming Date': row.batchIncomingDate ? new Date(row.batchIncomingDate) : null,
          'Total Individual Numbers': row.totalIndividualNumbers,
          'Total Unique VAT Numbers': row.totalUniqueVatNumbers,
          'Not Processed Individual Numbers': row.notProcessedIndividualNumbers,
          'Pipeline Errors': row.pipelineErrors,
          'Standard Delivered': row.standardDelivered,
          'Standard Review': row.standardReview,
          'Standard BS Only': row.numberOfBs,
          'Standard PL Only': row.numberOfIs,
          'Standard BS and PL': row.numberOfBsAndIs,
          'Standard Total': row.standardTotal,
          'Standard Delivery Date': row.deliveryDateStandard ? new Date(row.deliveryDateStandard) : null,
          'Non Standard Delivered': row.nonStandardDelivered,
          'Non Standard Review': row.nonStandardReview,
          'Non Standard BS Only': 0,
          'Non Standard PL Only': 0,
          'Non Standard BS and PL': 0,
          'Non Standard Total': row.nonStandardTotal,
          'Non Standard Delivery Date': row.deliveryDateNonStandard ? new Date(row.deliveryDateNonStandard) : null,
        };
        return flatRow;
      });
    };
  
    try {
      const exportData = getFlattenedData();
      const headers = Object.keys(exportData[0]);
      const worksheet = XLSX.utils.json_to_sheet(exportData);
  
      // Set column formats for date fields
      const dateColumns = ['Batch Incoming Date', 'Standard Delivery Date', 'Non Standard Delivery Date'];
      dateColumns.forEach((col) => {
        const colIndex = headers.indexOf(col) + 1; // Excel is 1-based index
        if (colIndex > 0) {
          worksheet[`!cols`] = worksheet[`!cols`] || [];
          worksheet[`!cols`][colIndex - 1] = { wch: 20 }; // Set column width
  
          // Convert dates to Excel's serial format
          for (let i = 1; i <= exportData.length; i++) {
            const cellAddress = XLSX.utils.encode_cell({ r: i, c: colIndex - 1 });
            if (worksheet[cellAddress] && worksheet[cellAddress].v instanceof Date) {
              worksheet[cellAddress].t = 'd'; // Mark as date
            }
          }
        }
      });
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Dashboard_Data');
  
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
  
      const fileData = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
  
      saveAs(
        fileData,
        `dashboard_export_${new Date().toISOString().split('T')[0]}.xlsx`
      );
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };

  const clearFilter = (columnId: string) => {
    setColumnFilters((prev) => {
      const newFilters = { ...prev };
      delete newFilters[columnId];
      return newFilters;
    });
    applyFilter();
  };

  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(total / pagination.pageSize),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: false,
  });

  return (
    <Box>
      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h5" fontWeight={600}>
            ICAP Dashboard
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={exportToExcel}
            sx={{
              backgroundColor: '#4CAF50',
              '&:hover': {
                backgroundColor: '#45a049',
              },
              textTransform: 'none',
              fontWeight: 500,
            }}
          >
            Export to Excel
          </Button>
        </Box>

        {isLoading ? (
          <Typography variant="h6">Loading data...</Typography>
        ) : (
          <div
            style={{
              maxHeight: '600px',
              overflowY: 'auto',
              border: '1px solid #ddd',
            }}
          >
            <table
              style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: 0,
              }}
            >
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  background: '#f5f5f5',
                  zIndex: 3,
                }}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          padding: '8px',
                          textAlign: 'center',
                          background: '#f5f5f5',
                          border: '1px solid #ddd',
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                <tr
                  style={{
                    position: 'sticky',
                    top: 40,
                    backgroundColor: 'inherit',
                    zIndex: 2,
                  }}
                >
                  {table.getAllLeafColumns().map((col) => {
                    const colId = col.id;
                    const isNumeric = typeof data?.[0]?.[colId] === 'number';
                    return (
                      <td
                        key={colId}
                        style={{
                          padding: '8px',
                          textAlign: 'center',
                          border: '1px solid #ddd',
                        }}
                      >
                        {isNumeric ? getColumnSum(colId) : ''}
                      </td>
                    );
                  })}
                </tr>

                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          border: '1px solid #ddd',
                          padding: '2px',
                          textAlign: 'center',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            marginTop: '16px',
          }}
        >
          <span style={{ marginRight: '16px' }}>
            Showing {pagination.pageIndex * pagination.pageSize + 1} to{' '}
            {Math.min((pagination.pageIndex + 1) * pagination.pageSize, total)}{' '}
            of {total} Rows
          </span>

          <TextField
            select
            label="Per Page"
            value={pagination.pageSize}
            onChange={(e) => {
              setPagination((prev) => ({
                ...prev,
                pageSize: Number(e.target.value),
                pageIndex: 0,
              }));
            }}
            sx={{
              width: '100px',
              marginRight: '16px',
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiInputBase-root': {
                height: '40px',
              },
            }}
            variant="outlined"
          >
            {[10, 25, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </TextField>

          <Pagination
            count={Math.ceil(total / pagination.pageSize)}
            page={pagination.pageIndex + 1}
            onChange={(event, value) => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: value - 1,
              }));
            }}
            variant="outlined"
            shape="rounded"
          />
        </Box>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {activeColumn && <FilterPopover columnId={activeColumn} />}
        </Popover>
      </Paper>
    </Box>
  );
};

export default App;
